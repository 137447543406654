:root {
    --color-border-primary: #605e5f;
    --color-border-secondary: #231f20;
    --color-bg-search: #a5a7aa;
    --color-bg: #c8cacd;
    --color-bg-input: #d5d6d8;
    --color-link-primary: #d2232a;
    --color-bg-footer: #605e5f;
    --color-soutien: #fff200;
    --color-text-princ: black;
}