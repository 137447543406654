.NavBar {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: black;
    color: white;
    box-shadow: 0 5px 10px rgba(0,0,0,0.5);
    margin-bottom: 15px;
}
.NavBarMenuTitleH2 {
    font-size: 1em;
    font-weight:initial;
    margin: 0;
    text-transform: uppercase;
}

.NavBarMenu a {
    color: white;
    transition: 200ms ease-in;
}
.NavBarMenu a:hover {
    color: var(--color-soutien);
    transform: scale(1.3);
}
.NavBarMenu i{
    transition: 200ms ease-in;
}

.NavBarMenu a:hover i{
    transform: scale(1.2);
}

.NavBarUserContainer {
    display: flex;
}
.NavBarIcon {
    margin: 0 15px;
    color: var(--color-soutien);
}

.NavBarMenuIcon {
    margin: 5px 10px;
}