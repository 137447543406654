.Loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Loading__grid {
    display: inline-grid;
    margin: 15px auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 5px;
}

.Loading__grid__point {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: black;
    opacity: 0;
}


.Loading__grid__point:nth-child(4){
    animation: 3s infinite Loading__grid__point__four;
}

.Loading__grid__point:nth-child(3){
    animation: 3s infinite Loading__grid__point__three;
    animation-delay: 200ms;
}

.Loading__grid__point:nth-child(2){
    animation: 3s infinite Loading__grid__point__two;
    animation-delay: 300ms;
}

.Loading__grid__point:nth-child(1){
    animation: 3s infinite Loading__grid__point__one;
    animation-delay: 400ms;
}

@keyframes Loading__grid__point__four {
    0% {
        opacity: 0;
        transform: translateX(-400%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
    }


    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes Loading__grid__point__three {
    0% {
        opacity: 0;
        transform: translateX(-300%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }

}

@keyframes Loading__grid__point__two{
    0% {
        opacity: 0;
        transform: translateX(-200%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }

}

@keyframes Loading__grid__point__one {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }

}