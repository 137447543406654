.cross {
    position: relative;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    cursor: pointer;
    transition: 200ms ease-in-out;
}

.cross:after, .cross:before{
    content: '';
    position: absolute;
    display: block;
    background: var(--color-link-primary);
    border-radius: 20px;
    transition: 200ms ease-in-out;
}

.cross:before {
    width: 4px;
    height: 100%;
    left: calc(50% - 2px);
}

.cross:after {
    width: 100%;
    height: 4px;
    top: calc(50% - 2px);
}

.cross:hover {
    transform: rotate(135deg);
}

.cross:hover:after, .cross:hover:before{
    background: var(--color-txt-error);
    border-radius: 0;
}