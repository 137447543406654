.trPop-enter {
    opacity: 0;
    transform: scale(0.9);
}
.trPop-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.trPop-exit {
    opacity: 1;
}
.trPop-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}