.Login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: calc(100vh - 40px);
    margin: 20px;

}

.LoginContainer{
    background: var(--color-bg-input);
    border: 5px solid black;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.05);
    padding: 15px;
}

.LoginLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.LoginLogo img {
    max-width: 150px;
    display: block;
}

.LoginForm {
    border-top: 2px solid rgba(0,0,0,0.05);
    padding-top: 10px;
    display: flex;
    flex-direction: column;
}

.LoginTitle {
    font-size: 1.8em;
    text-align: center;
    color: var(--color-link-primary);
    line-height: 10px;
}

.LoginSubTitle {
    font-size: 1em;
    font-style: italic;
    text-align: right;
}

.LoginInput {
    width: 100%;
    margin-bottom: 10px;
}

.LoginInputLabel {
    font-size: 0.8em;
    color: rgba(0,0,0,.6);
    padding-left: 30px;
    line-height: 3px;
    margin: 0;
}

.LoginRegister {
    color: var(--color-link-primary);
    font-size: 0.9em;
    text-align: center;
    margin: 15px 5px 5px;
    cursor: pointer;
    transition: 100ms ease-in;
}

.LoginRegister:hover {
    transform: scaleX(1.1);
}


@media screen and (max-width: 300px){
    .LoginLogo {
        min-width: initial;
        width: calc(100% - 40px);
    }
}