.ClassicInput {
    position: relative;
    display: flex;
    height: inherit;
    width: 100%;
    margin: 10px;
}

.ClassicInputInput {
    display: block;
    padding: 10px;
    font-size: 1em;
    border-radius: 30px;
    outline: none;
    border: 2px solid var(--color-bg-accent);
    transition: 200ms ease-in-out;
}

.ClassicInputInput:focus {
    border-radius: 10px;
    border: 2px solid var(--color-arrd);
    box-shadow: 3px 3px 5px rgba(0,0,0,.2);
}

.ClassicInputCross {
    position: absolute;
    top: calc(50% - 10px);
    right: 30px;
    opacity: .7;
}