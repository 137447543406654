
.CropperContainerButtons{
    display: flex;
    margin: 10px;
    align-items: center;
    justify-content: center;
}
.CropperButtons{
    margin: 15px;
}

.CropperInputInput{
    display: none;
}

.CropperInput {
    display: flex;
    justify-content: center;
}
.CropperInputLabel {
    text-align: center;
    cursor: pointer;
    transition: 200ms ease-in;
}

.CropperInputLabel:hover {
    transform: scale(1.05);
}