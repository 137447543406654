@import url(https://fonts.googleapis.com/css2?family=Anton&family=Oxygen:wght@300;400;700&display=swap);

.Loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Loading__grid {
    display: inline-grid;
    margin: 15px auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 5px;
}

.Loading__grid__point {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: black;
    opacity: 0;
}


.Loading__grid__point:nth-child(4){
    -webkit-animation: 3s infinite Loading__grid__point__four;
            animation: 3s infinite Loading__grid__point__four;
}

.Loading__grid__point:nth-child(3){
    -webkit-animation: 3s infinite Loading__grid__point__three;
            animation: 3s infinite Loading__grid__point__three;
    -webkit-animation-delay: 200ms;
            animation-delay: 200ms;
}

.Loading__grid__point:nth-child(2){
    -webkit-animation: 3s infinite Loading__grid__point__two;
            animation: 3s infinite Loading__grid__point__two;
    -webkit-animation-delay: 300ms;
            animation-delay: 300ms;
}

.Loading__grid__point:nth-child(1){
    -webkit-animation: 3s infinite Loading__grid__point__one;
            animation: 3s infinite Loading__grid__point__one;
    -webkit-animation-delay: 400ms;
            animation-delay: 400ms;
}

@-webkit-keyframes Loading__grid__point__four {
    0% {
        opacity: 0;
        transform: translateX(-400%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
    }


    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes Loading__grid__point__four {
    0% {
        opacity: 0;
        transform: translateX(-400%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
    }


    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes Loading__grid__point__three {
    0% {
        opacity: 0;
        transform: translateX(-300%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }

}

@keyframes Loading__grid__point__three {
    0% {
        opacity: 0;
        transform: translateX(-300%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }

}

@-webkit-keyframes Loading__grid__point__two{
    0% {
        opacity: 0;
        transform: translateX(-200%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }

}

@keyframes Loading__grid__point__two{
    0% {
        opacity: 0;
        transform: translateX(-200%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }

}

@-webkit-keyframes Loading__grid__point__one {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }

}

@keyframes Loading__grid__point__one {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    70% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }

}
.Subscribers{
    max-height: calc(100vh - 100px);
    overflow: auto;
    border: 1px solid #fff200;
}


.CropperContainerButtons{
    display: flex;
    margin: 10px;
    align-items: center;
    justify-content: center;
}
.CropperButtons{
    margin: 15px;
}

.CropperInputInput{
    display: none;
}

.CropperInput {
    display: flex;
    justify-content: center;
}
.CropperInputLabel {
    text-align: center;
    cursor: pointer;
    transition: 200ms ease-in;
}

.CropperInputLabel:hover {
    transform: scale(1.05);
}
.Login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: calc(100vh - 40px);
    margin: 20px;

}

.LoginContainer{
    background: var(--color-bg-input);
    border: 5px solid black;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.05);
    padding: 15px;
}

.LoginLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.LoginLogo img {
    max-width: 150px;
    display: block;
}

.LoginForm {
    border-top: 2px solid rgba(0,0,0,0.05);
    padding-top: 10px;
    display: flex;
    flex-direction: column;
}

.LoginTitle {
    font-size: 1.8em;
    text-align: center;
    color: var(--color-link-primary);
    line-height: 10px;
}

.LoginSubTitle {
    font-size: 1em;
    font-style: italic;
    text-align: right;
}

.LoginInput {
    width: 100%;
    margin-bottom: 10px;
}

.LoginInputLabel {
    font-size: 0.8em;
    color: rgba(0,0,0,.6);
    padding-left: 30px;
    line-height: 3px;
    margin: 0;
}

.LoginRegister {
    color: var(--color-link-primary);
    font-size: 0.9em;
    text-align: center;
    margin: 15px 5px 5px;
    cursor: pointer;
    transition: 100ms ease-in;
}

.LoginRegister:hover {
    transform: scaleX(1.1);
}


@media screen and (max-width: 300px){
    .LoginLogo {
        min-width: 0;
        min-width: initial;
        width: calc(100% - 40px);
    }
}
.ClassicInput {
    position: relative;
    display: flex;
    height: inherit;
    width: 100%;
    margin: 10px;
}

.ClassicInputInput {
    display: block;
    padding: 10px;
    font-size: 1em;
    border-radius: 30px;
    outline: none;
    border: 2px solid var(--color-bg-accent);
    transition: 200ms ease-in-out;
}

.ClassicInputInput:focus {
    border-radius: 10px;
    border: 2px solid var(--color-arrd);
    box-shadow: 3px 3px 5px rgba(0,0,0,.2);
}

.ClassicInputCross {
    position: absolute;
    top: calc(50% - 10px);
    right: 30px;
    opacity: .7;
}
.Authenticator {
    background: var(--color-bg);
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Dashboard{
    box-sizing: border-box;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
}
.DashboardMain {
    overflow: auto;
    padding: 10px;
}
.NavBar {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: black;
    color: white;
    box-shadow: 0 5px 10px rgba(0,0,0,0.5);
    margin-bottom: 15px;
}
.NavBarMenuTitleH2 {
    font-size: 1em;
    font-weight:normal;
    font-weight:initial;
    margin: 0;
    text-transform: uppercase;
}

.NavBarMenu a {
    color: white;
    transition: 200ms ease-in;
}
.NavBarMenu a:hover {
    color: var(--color-soutien);
    transform: scale(1.3);
}
.NavBarMenu i{
    transition: 200ms ease-in;
}

.NavBarMenu a:hover i{
    transform: scale(1.2);
}

.NavBarUserContainer {
    display: flex;
}
.NavBarIcon {
    margin: 0 15px;
    color: var(--color-soutien);
}

.NavBarMenuIcon {
    margin: 5px 10px;
}
:root {
    --color-border-primary: #605e5f;
    --color-border-secondary: #231f20;
    --color-bg-search: #a5a7aa;
    --color-bg: #c8cacd;
    --color-bg-input: #d5d6d8;
    --color-link-primary: #d2232a;
    --color-bg-footer: #605e5f;
    --color-soutien: #fff200;
    --color-text-princ: black;
}
body{
    font-family: 'Oxygen', sans-serif;
    font-size: 18px;
    box-sizing: border-box;
    background: var(--color-bg);
    margin: 0;
}

a {
    text-decoration: none;
    color: var(--color-link-primary);
    transition: 200ms ease-in;
}

a:hover {
    color: black;
}
h1,h2,h3,h4,h5 {
    font-family: 'Anton', sans-serif;
}

h1 {
    font-size: 1.5em;
}

h2 {
    font-size: 1.3em;
}

button {
    outline: none;
    background: white;
    padding: 10px;
    font-size: 1.1em;
    border: 3px solid black;
    box-shadow: 7px 7px 0 black;
    cursor: pointer;
    transition: 200ms ease-in;
}

button:focus {
    box-shadow: 2px 2px 0 black;
}

h3 {
    font-size: 1.2em;
}

.error {
    text-align: center;
    color: var(--color-link-primary);
    font-size: 0.8em;
}
.cross {
    position: relative;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    cursor: pointer;
    transition: 200ms ease-in-out;
}

.cross:after, .cross:before{
    content: '';
    position: absolute;
    display: block;
    background: var(--color-link-primary);
    border-radius: 20px;
    transition: 200ms ease-in-out;
}

.cross:before {
    width: 4px;
    height: 100%;
    left: calc(50% - 2px);
}

.cross:after {
    width: 100%;
    height: 4px;
    top: calc(50% - 2px);
}

.cross:hover {
    transform: rotate(135deg);
}

.cross:hover:after, .cross:hover:before{
    background: var(--color-txt-error);
    border-radius: 0;
}
.trPop-enter {
    opacity: 0;
    transform: scale(0.9);
}
.trPop-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.trPop-exit {
    opacity: 1;
}
.trPop-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
