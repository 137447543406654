@import url('https://fonts.googleapis.com/css2?family=Anton&family=Oxygen:wght@300;400;700&display=swap');
body{
    font-family: 'Oxygen', sans-serif;
    font-size: 18px;
    box-sizing: border-box;
    background: var(--color-bg);
    margin: 0;
}

a {
    text-decoration: none;
    color: var(--color-link-primary);
    transition: 200ms ease-in;
}

a:hover {
    color: black;
}
h1,h2,h3,h4,h5 {
    font-family: 'Anton', sans-serif;
}

h1 {
    font-size: 1.5em;
}

h2 {
    font-size: 1.3em;
}

button {
    outline: none;
    background: white;
    padding: 10px;
    font-size: 1.1em;
    border: 3px solid black;
    box-shadow: 7px 7px 0 black;
    cursor: pointer;
    transition: 200ms ease-in;
}

button:focus {
    box-shadow: 2px 2px 0 black;
}

h3 {
    font-size: 1.2em;
}

.error {
    text-align: center;
    color: var(--color-link-primary);
    font-size: 0.8em;
}